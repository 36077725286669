<template>
  <b-overlay :show="loading">
    <b-card v-if="flexColumn" class="d-flex" :no-body="noCardBody">
      <div v-for="(attachment, i) in attachments" :key="i" class="mr-1 cursor-pointer mb-2" @click="() => showImg(index)">
        <p v-if="i === 0 && showLabels" class="font-weight-bold">
          Dashboard Profile/ID
        </p>

        <p v-if="i === 1 && showLabels" class="font-weight-bold">
          Payment Slip / Appointment Letter
        </p>
        <b-card-img 
          :src="getValueFromSource(attachment, 'path')" 
          :alt="getValueFromSource(attachment, 'name')" 
          fluid 
          style="max-height: 300px"
          @load="onImgLoad"
        />
      </div>
      
      <vue-easy-lightbox
        :visible="visible"
        :imgs="attachedImages"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </b-card>

    <div v-else class="d-flex">
      <b-card 
        v-for="(attachment, i) in attachments" 
        :key="i" 
        class="mr-1 cursor-pointer mb-2" 
        :no-body="noCardBody"
        @click="() => showImg(index)"
      >
        <p v-if="i === 0 && showLabels" class="font-weight-bold">
          Dashboard Profile/ID
        </p>

        <p v-if="i === 1 && showLabels" class="font-weight-bold">
          Payment Slip  / Appointment Letter
        </p>
        <b-card-img 
          :src="getValueFromSource(attachment, 'path')" 
          :alt="getValueFromSource(attachment, 'name')" 
          fluid 
          style="max-height: 500px"
          @load="onImgLoad"
        />
      </b-card>
      
      <vue-easy-lightbox
        :visible="visible"
        :imgs="attachedImages"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </div>
  </b-overlay>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

import {
  BOverlay, BCard, BCardBody, BAlert, BLink, BRow, BCol, BCardImg
} from "bootstrap-vue"

export default {
  components: {
    BOverlay, BCard, BCardBody, BAlert, BLink, BRow, BCol, BCardImg, VueEasyLightbox
  },
  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    flexColumn: {
      type: Boolean,
      default: false
    },
    showLabels: {
      type: Boolean,
      default: true
    },
    noCardBody: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      loading: true,
      imagesloaded: 0,
      visible: false,
      index: 0, // default: 0
    }
  },
  computed: {
    attachedImages(){
      return this.attachments.map(att => ({ src: att.path, title: att.name }));
    }
  },
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    onImgLoad(){
      console.log("image loaded")
      this.imagesloaded += 1;
      if (this.imagesloaded === this.attachments.length){
        this.loading = false;
      }
    }
  }
}
</script>
