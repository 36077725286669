<template>
  <section>
    <div class="mt-3">
      <app-timeline>
        <app-timeline-item
            v-for="(reason, i) in flaggedReasons"
            :key="i"
            :variant="variant"
            icon="InfoIcon"
        >
          <div>
            <h4 v-if="isCurrentUserAdmin">
              {{ formatString(reason.error) }}
            </h4>
          </div>
          <div>
            <p>
              {{ reason.description }}
            </p>
          </div>

        </app-timeline-item>
      </app-timeline>
    </div>
  </section>
</template>

<script>
import { BCard, BCardBody, BAlert, BLink, BRow, BCol } from "bootstrap-vue"
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import { lowerCase, upperFirst } from "lodash";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardBody,
    BAlert,
    BLink,
    BRow,
    BCol
  },
  props: {
    flaggedReasons: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: ""
    }
  },
  methods: {
    formatString(string) {
      return upperFirst(lowerCase(string));
    }
  }
}
</script>
